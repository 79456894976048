import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"
import get from "lodash/get"

import Seo from "../components/Seo"
import Breadcrumbs from "../components/Breadcrumbs"
import HeroImage from "../components/Image/HeroImage"
import RichText from "../components/RichText"
import Cards from "../components/Cards/Cards"

import PrintIcon from "../images/svg/icon_print.svg"

import { getFirstArrayItem, getPlainText, getPrettyURL } from "../utils"
import { PageModels, RecipeCollectionModel } from "../models/pages"
import { SeoDataModel } from "../models/common"
import useDictionary from "../hooks/useDictionary"

import { fetchLivePreviewData, contentstackLivePreviewWrapper } from "../live-preview"

const defaultProps = {}

export const RecipeCollectionPage: React.FC<RecipeCollectionModel> = props => {
  // page data
  const _page =  get(props, "data.page") as any as RecipeCollectionModel;
  _page.pageContext = props.pageContext
  const [page, setPage] = useState(_page);

    useEffect(() => {
      contentstackLivePreviewWrapper(fetchLivePreviewData(_page, setPage, props.pageContext))
  }, [])

  // CMS data
  // const listingPage = (getFirstArrayItem(
  //   get(props, "data.listingPage") as any as PageModels)?.pages
  // )

  const heroImage = page.hero.hero_image

  // page heading
  const heroHeading = getPlainText(page.hero.heading)
  // const navLabel = getPlainText(page.nav_label)

  // SEO Fields
  const seoData: SeoDataModel = {
    heading: heroHeading,
    metaTitle: page.seo?.title || heroHeading,
    metaDescription: page.seo?.description || getPlainText(page.introduction),
    metaLink: page.seo?.canonical_link,
    metaKeywords: page.seo?.keywords,
    metaImage: page.seo?.share_image || heroImage,
    slug: `/${props.pageContext.language}/${getPrettyURL(page)}`,
    locale: props.pageContext.locale,
    pageContext: props.pageContext,
  }

  const onPrintClick = () => {
      window.print();
  }

  return (
    <div className="recipeCollectionPage">
      <Seo {...seoData} />

      {/* Breadcrumbs */}
      <Breadcrumbs
        breadcrumbs={page.url_structure}
        pageContext={props.pageContext}
      />

      {/* Hero */}
      <section className="hero with-sidebar">
        <div className="grid-container">
          <div className="grid-x grid-margin-x">
            <div className="cell large-auto">
              <div className="page__body">
                <HeroImage
                  image={page.hero.hero_image}
                  focus={page.hero.image_focus}
                />
              </div>
            </div>
            <div className="cell large-shrink">
              <div className="page__sidebar">
                <h2>{heroHeading}</h2>
                <RichText
                  content={page.introduction}
                  cssClass="content"
                  pageContext={props.pageContext}
                />
                <br />
                <button
                  className="button"
                  onClick={onPrintClick}
                >
                  <PrintIcon
                    width="24"
                    height="24"
                    fill="#fff"
                    style={{
                      display: "inline-block",
                      verticalAlign: "middle",
                      marginTop: -6,
                      marginRight: 8,
                    }}
                  />
                  <span>
                    {useDictionary(
                      "Create print list",
                      props.pageContext.locale
                    )}
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Recipes */}
      <Cards cards={page.recipes} pageContext={props.pageContext} />
    </div>
  )
}

RecipeCollectionPage.defaultProps = defaultProps

export default RecipeCollectionPage

/****
	Data
****/
export const query = graphql`
  query RecipeCollectionPageByID($pageId: String!, $locale: String) {
    page: contentstackRecipeCollection(id: { eq: $pageId }) {
      __typename
      uid
      id
      url
      hero {
        heading
        hero_image {
          ...HeroImageFields
        }
      }
      introduction
      recipes {
        ...RecipeFields
      }
      seo {
        title
        description
        canonical_link
        keywords
        share_image {
          ...CmsImageFields
          gatsbyImageData(quality: 95)
        }
      }
      internal {
        type
      }
    }

    listingPage: contentstackConfig(
      code_id: { eq: "recipePage" }
      publish_details: { locale: { eq: $locale } }
    ) {
      pages {
        ... on Contentstack_recipe_listing {
          ...RecipeListingFields
        }
      }
    }
  }

  fragment RecipeCollectionFields on Contentstack_recipe_collection {
    id
    url
    hero {
      heading
      image_focus
      listing_img: hero_image {
        ...CmsImageFields
        gatsbyImageData(height: 240, quality: 95)
      }
      mini_img: hero_image {
        ...CmsImageFields
        gatsbyImageData(height: 75, quality: 95)
      }
    }
    recipes {
      id
    }
    internal {
      type
    }
    publish_details {
      locale
    }
  }
`
